<template>
	<v-app>
		<v-main>
			<v-container class="fill-height" fluid>
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4">
						<v-card>
							<v-toolbar color="primary" dark flat>
								<img class="fsk-navbar__logo mx-auto" src="@/assets/img/fadesk-logo-white.svg" alt="Fadesk">
							</v-toolbar>
							<v-card-text>
								<v-form ref="loginform" @submit.prevent="login()">
									<v-text-field v-model="email" class="fsk-v-input mb-3" label="Email" name="email" type="email" outlined dense hide-details="auto"></v-text-field>
									<v-text-field v-model="password" class="fsk-v-input mb-5" label="Password" name="password" type="password" outlined dense hide-details="auto"></v-text-field>
									<v-btn type="submit" color="primary" large block :loading="loading" :disabled="isButtonDisabled">Login</v-btn>  
								</v-form>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>

		<v-snackbar v-model="invalid" :timeout="3000">
			Invalid login credentials, please try again.
		</v-snackbar>
	</v-app>
</template>

<script>
export default {
	name: 'Login',
	
	data: () => ({
		email: '',
		password: '',
		loading: false,
		invalid: false,
	}),
	
	computed: {
		isButtonDisabled() {
			return this.email == '' || this.password == ''
		}
	},
	
	methods: {
		login() {
			if (!this.isButtonDisabled) {
				// Show loader
				this.loading = true;
				
				const formData = new FormData();
				formData.append('email', this.email);
				formData.append('password', this.password);
				
				this.$axios.post('/login', formData)
					.then(response => {
						// Set auth headers
						this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data['access_token'];

						// Store access token
						localStorage.setItem('fsk_token', response.data['access_token']);
						
						// Redirect to dashboard
						setTimeout(() => {
							this.$router.replace('/');
						}, 100)
					})
					.catch(error => {
						// If creds are invalid
						if (error.response.status == 401) {
							this.invalid = true;
						}
					})
			}
			
		}
	}
};
</script>
